<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="container">
        <ul class="footer__docs">
          <li class="footer__docs-item footer__logo-wrapper">
            <a href="/" class="footer__logo">
              <img
                src="../assets/images/logo.svg"
                alt=""
                class="footer__logo-img"
              />
            </a>
          </li>
          <li class="footer__docs-item" @click="$modal.show('feedback')">
            <span>обратная связь</span>
          </li>
          <li v-for="(item, idx) in docs" :key="idx" class="footer__docs-item">
            <a
              :href="`${publicPath}docs/` + item.path"
              class="footer__docs-link"
              target="_blank"
              @click="
                item.gtm
                  ? (gtmEvent('rules', 'click', 'send'),
                    gtmEvent('rules', 'click', 'internal'))
                  : ''
              "
            >
              {{ item.name }}
            </a>
          </li>
        </ul>
        <div class="footer__text">
          Период приобретения товаров
          с&nbsp;15.08.2024&nbsp;г.&nbsp;по&nbsp;31.10.2024&nbsp;г. включительно
          во&nbsp;всех магазинах &laquo;Пятёрочка&raquo; и&nbsp;в&nbsp;мобильном
          приложении &laquo;Пятёрочка&raquo; на&nbsp;территории РФ. В&nbsp;акции
          участвуют любые продукты под товарным знаком Jaguar.Extra Urban
          Energy. Организатором Акции является Общество с&nbsp;ограниченной
          ответственностью &laquo;ПЕРСПЕКТИВА&raquo;, ИНН 4705044950,
          юридический адрес: 141014, Московская обл., Городской округ Мытищи, г.
          Мытищи, ул. Веры Волошиной, д. 19/16. Период вручения призов
          Победителям Акции
          с&nbsp;22.08.2024&nbsp;г.&nbsp;до&nbsp;31.12.2024&nbsp;г.
          С&nbsp;информацией об&nbsp;организаторе, правилах Акции и&nbsp;сроках
          ее&nbsp;проведения, количестве призов и&nbsp;выигрышей
          по&nbsp;результатам акции, месте и&nbsp;порядке их&nbsp;получения
          можно подробнее ознакомиться по&nbsp;адресу https://jaguar-energy.ru/.
          Призы могут отличаться внешне от&nbsp;изображенных на&nbsp;макете.<br />
          Erid: 2SDnje8UBNJ
        </div>
        <!-- <div class="footer__copyright">
          © <span>{{ year }}</span> Все права защищены.
        </div> -->
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  components: {},
  props: ["partners"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      docs: [
        {
          name: "правила акции",
          path: "rules.pdf",
          gtm: true,
        },
        {
          name: "политика конфиденциальности",
          path: "terms-of-use.pdf",
        },
      ],
    };
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollTo() {
      const faq = document.getElementById("faq").offsetTop;
      window.scroll({
        top: faq,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    year() {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(24px) 0 rem(45px);
  border-bottom: none;
  overflow: hidden;
  pointer-events: none;
  z-index: 10;
  * {
    pointer-events: all;
  }
  &__row,
  &__docs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__docs {
    margin-bottom: rem(20px);
    &-item {
      margin-bottom: rem(28px);
      font-size: rem(14px);
      line-height: 1;
      text-transform: uppercase;
      font-family: $medium;
      letter-spacing: 0.05em;
    }
  }
  &__text,
  &__copyright {
    font-size: rem(10px);
    line-height: rem(13px);
    p {
      margin-bottom: rem(5px);
      // overflow-wrap: break-word;
      // word-wrap: break-word;
      // -webkit-hyphens: auto;
      // -ms-hyphens: auto;
      // -moz-hyphens: auto;
      // hyphens: auto;
    }
    a {
      white-space: nowrap;
    }
  }
  &__logo {
    &-wrapper {
      margin-bottom: rem(52px);
    }
    &-img {
      height: rem(61px);
    }
  }
  &__col {
    width: 100%;
  }
  &__copyright {
    margin-top: rem(12px);
  }

  @media (orientation: landscape) {
    padding: rem(24px) 0;
    font-size: rem(13px);
    text-align: left;
    .container {
      width: rem(1180px);
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &__logo {
      &-wrapper {
        margin-bottom: 0;
        margin-right: auto;
      }
      &-img {
        height: rem(60px);
      }
    }
    &__content {
      padding-top: 0;
      background: none;
    }
    &__row,
    &__docs {
      flex-direction: row;
      align-items: center;
    }
    &__docs {
      margin-bottom: rem(28px);
      text-transform: uppercase;
      font-size: rem(14px);
      &-item {
        margin-bottom: 0;
        line-height: normal;
        cursor: pointer;
        &:not(:first-of-type) {
          margin-left: rem(30px);
        }
      }
    }
    &__copyright {
      margin-top: 0;
      text-align: left;
      font-size: rem(16px);
    }
    &__text {
      font-size: rem(10px);
      line-height: 1.2;
    }
    &__col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &:last-of-type {
        flex: 0 0 58%;
      }
    }
  }
}
</style>
