import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import ComingSoon from "../views/ComingSoon.vue";

Vue.use(VueRouter);

const titlePrefix = "Участвуй в гонке за призами с Jaguar";
const store = require("../store");

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // redirect: '/coming-soon',
    meta: {
      title: titlePrefix,
    },
  },
  {
    path: "/profile",
    // redirect: '/coming-soon',
    name: "Profile",
    component: () => import("../views/account/Profile.vue"),
    meta: {
      title: titlePrefix,
    },
  },
  {
    path: "/form",
    // redirect: '/coming-soon',
    name: "Form",
    component: () => import("../views/account/Form.vue"),
    meta: {
      title: titlePrefix,
    },
  },
  {
    path: "/new",
    // redirect: '/coming-soon',
    name: "New",
    component: () => import("../views/New.vue"),
    meta: {
      title: titlePrefix,
    },
  },
  // {
  //   path: "/coming-soon",
  //   name: "ComingSoon",
  //   component: ComingSoon,
  //   meta: {
  //     title: titlePrefix + "Акция начнется 1 октября 2021 г.",
  //   },
  // },
  // { path: "/:pathMatch(.*)*", name: "NotFound", component: Home },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
        offset: {
          y: 0,
        },
      };
    }
    return { x: 0, y: 0 }; // Go to the top of the page if no hash
  },
});

export default router;
