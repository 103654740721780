import qs from "qs";
import request from "../utils/request";

export function signIn(data) {
  const options = qs.stringify(data);

  return request({
    url: "profiles/api/authorization",
    method: "post",
    data: options,
  });
}

export function signUp(data) {
  const options = qs.stringify(data);

  return request({
    url: "/profiles/api/register-by-email",
    method: "POST",
    data: options,
  });
}

export function checkRegisterByEmail(data) {
  const options = qs.stringify(data);

  return request({
    url: "/checkRegisterByEmail ",
    method: "POST",
    data: options,
  });
}
export function sendSms(data) {
  const options = qs.stringify(data);

  return request({
    url: "/sendSms ",
    method: "POST",
    data: options,
  });
}

export function sendCode(data) {
  console.log(data);
  const options = qs.stringify(data.fields);
  return request({
    url: `/profiles/api/confirm-email-by-code/${data.id}`,
    method: "POST",
    data: options,
  });
}
export function resendCode(data) {
  console.log(data);
  const options = qs.stringify(data.fields);
  return request({
    url: `/profiles/api/resend-email-confirm-code/${data.id}`,
    method: "POST",
    data: options,
  });
}

export function recover(data) {
  const options = qs.stringify(data);

  return request({
    url: "/profiles/api/resend-password",
    method: "post",
    data: options,
  });
}

export function registerByEmailSoc(data) {
  const options = qs.stringify(data);

  return request({
    url: "/registerByEmailSoc",
    method: "post",
    data: options,
  });
}

export function resetPassword(data) {
  const options = qs.stringify(data);

  return request({
    url: "/profiles/api/change-password",
    method: "post",
    data: options,
  });
}
