<template>
  <div class="prizes-main-prize">
    <div class="container _small-width">
      <div class="prizes-main-prize__content">
        <div class="prizes-main-prize__title section-title _white">
          главный приз
        </div>
        <div
          class="prizes-main-prize__text"
          v-html="
            mainPrizeSubtitle
              ? mainPrizeSubtitle
              : 'Проголосуй за тачку <br />от любимого героя'
          "
        ></div>
        <img
          src="../assets/images/prizes/cars.png"
          alt=""
          class="prizes-main-prize__cars"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["mainPrizeSubtitle"],
};
</script>

<style lang="scss" scoped>
.prizes {
  &-main-prize {
    position: relative;
    width: 100%;
    padding: rem(66px) 0 rem(140px);
    background: url("../assets/images/prizes/bg-2.png") no-repeat center;
    background-size: auto 100%;
    &.mt-big {
      margin-top: rem(60px);
    }
    ._profile & {
      padding-bottom: rem(60px);
      background-position: center top;
      background-size: auto 130%;
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: rem(10px);
      align-items: center;
      text-align: center;
    }
    &__title.section-title {
      margin-bottom: rem(16px);
    }
    &__text {
      margin-bottom: rem(80px);
      font-family: $light;
      text-transform: uppercase;
    }
    &__cars {
      max-width: none;
      width: 150%;
    }
  }
  @media (orientation: landscape) {
    &-main-prize {
      position: relative;
      padding: rem(18px) 0 rem(100px);
      background: url("../assets/images/prizes/bg-2.png") no-repeat center;
      background-size: 100%;
      &.mt-big {
        margin-top: rem(100px);
      }
      ._profile & {
        background-position: center;
        background-size: 100%;
      }
      &__content {
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &__title.section-title {
        margin-bottom: rem(16px);
      }
      &__text {
        margin-bottom: rem(12px);
        font-size: rem(24px);
        line-height: rem(32px);
      }
      &__cars {
        width: 100%;
      }
    }
  }
}
</style>
