var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form"},[_c('ul',{staticClass:"modal-nav"},[_c('li',{staticClass:"modal-nav__item _pointer",on:{"click":function($event){return _vm.showLogInModal()}}},[_vm._v("Вход")]),_c('li',{staticClass:"modal-nav__item active"},[_vm._v("Регистрация")])]),_c('div',{staticClass:"form__fields"},[_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.first_name) || _vm.errorMessage.first_name,
      },attrs:{"label":"Ваше имя","placeholder":"Введи имя","field":_vm.first_name,"error":(!_vm.$v.first_name.required && _vm.$v.first_name.$error
          ? 'Обязательное поле'
          : !_vm.$v.first_name.rus && _vm.$v.first_name.$error
          ? 'Введи текст на русском языке'
          : '') || _vm.errorMessage.first_name},on:{"input":function($event){_vm.first_name = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.email) || this.errorMessage.email,
      },attrs:{"label":"E-mail","field":_vm.email,"placeholder":"Введи EMAIL","type":"email","error":(!_vm.$v.email.required && _vm.$v.email.$error
          ? 'Обязательное поле'
          : !_vm.$v.email.email && _vm.$v.email.$error
          ? 'Некорректный e-mail'
          : '') || this.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.x5id) || _vm.errorMessage.x5id,
      },attrs:{"label":"Карта «Х5 Клуба» (необязательно)","placeholder":"Номер карты","field":_vm.x5id,"maxlength":"16","error":(!_vm.$v.x5id.maxLength && _vm.$v.x5id.$error
          ? 'Максимум 16 символов'
          : '') || _vm.errorMessage.x5id},on:{"input":function($event){_vm.x5id = $event}}})],1),_c('div',{staticClass:"form__note"},[_vm._v(" *Для получения гарантированного приза необходимо заполнить номер карты «Х5 Клуба» ")]),_c('div',{staticClass:"form__checkbox-list"},[_c('checkBox',{class:{
        error: _vm.validationStatus(_vm.$v.agree_rules) || _vm.errorMessage.agree_rules,
      },attrs:{"label":`Я ознакомился с&nbsp;<a href='${_vm.publicPath}docs/terms-of-use.pdf' class='_undeline-link _yellow' target='_blank'>положением о&nbsp;конфиденциальности</a> и&nbsp;<a href='${_vm.publicPath}docs/rules.pdf' class='_undeline-link _yellow' target='_blank'>правилами акции</a>`,"field":_vm.agree_rules,"error":_vm.errorMessage.agree_rules},on:{"input":function($event){_vm.agree_rules = $event}}}),_c('checkBox',{class:{
        error: _vm.validationStatus(_vm.$v.agree_pd) || _vm.errorMessage.agree_pd,
      },attrs:{"label":`Я&nbsp;согласен получать информационные сообщения от&nbsp;компании ООО &laquo;X5&nbsp;Клуб&raquo; и&nbsp;Организатора акции`,"field":_vm.agree_pd,"error":_vm.errorMessage.agree_pd},on:{"input":function($event){_vm.agree_pd = $event}}})],1),_c('div',{staticClass:"form__footer"},[_c('button',{staticClass:"btn btn--yellow w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_c('span',[_vm._v("Зарегистрироваться")]),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/images/icons/spinner.svg"),"alt":""}})]),_c('div',{staticClass:"modal__another-option"},[_c('p',[_vm._v(" Уже зарегистрированы? "),_c('a',{staticClass:"_undeline-link _yellow",attrs:{"href":"#"},on:{"click":function($event){return _vm.showLogInModal()}}},[_vm._v("Войти")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }